import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/typescript-features-you-should-no-longer-use",
  "date": "2021-11-12",
  "title": "TypeScript Features You Should No Longer Use",
  "author": "admin",
  "tags": ["development", "javascript", "typescript"],
  "featuredImage": "feature.jpg",
  "excerpt": "When TypeScript was originally invented by Microsoft in 2010, they implemented features missing from JavaScript at the time including module systems, classes, enums and decorators."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`But in 2015 JavaScript added some of these features into the ECMAScript standard when ES6 was standardised. `}</p>
    <p>{`TypeScript since then changed its governing principle to adapt to these changes and focus more on the type space and leave JavaScript to govern the runtime.`}</p>
    <p>{`There are still a few remaining TypeScript features before this decision and they don’t fit the pattern of the rest of the language. To keep the relationship between TypeScript and JavaScript as clear as possible we should avoid these features.`}</p>
    <h2>{`Decorators`}</h2>
    <p>{`Decorators provide a way to add both annotations and a meta-programming syntax for class declarations and members. Decorators can be used in five ways:`}</p>
    <ul>
      <li parentName="ul">{`class declaration`}</li>
      <li parentName="ul">{`property`}</li>
      <li parentName="ul">{`method`}</li>
      <li parentName="ul">{`parameter`}</li>
      <li parentName="ul">{`accessor`}</li>
    </ul>
    <h3>{`Class Decorator`}</h3>
    <p>{`When a class is decorated you have to be careful with inheritance because its descendants will not inherit the decorators. Let’s freeze the class to prevent inheritance completely.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`@Freeze
class Person {}

function Freeze(constructor: Function) {
  Object.freeze(constructor);
  Object.freeze(constructor.prototype);
}

console.log(Object.isFrozen(Person)); // true

class Cat extends Person {} // error, cannot be extended
`}</code></pre>
    <h3>{`Class Decorator`}</h3>
    <p>{`Property decorators can be used for listening to state changes in a class. `}</p>
    <p>{`Let’s override the name property to surround it in emojis. This allows us to set a regular string value, but run additional code on get/set as `}<strong parentName="p"><em parentName="strong">{`middleware`}</em></strong>{`, if you will.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`export class Person {
  @Emoji()
  name = 'Sean';
}

// Property Decorator
function Emoji() {
  return function(target: Object, key: string | symbol) {

    let val = target[key];

    const getter = () =>  {
        return val;
    };
    const setter = (next) => {
        console.log('updating name...');
        val = \`\${next}\`;
    };

    Object.defineProperty(target, key, {
      get: getter,
      set: setter,
      enumerable: true,
      configurable: true,
    });

  };
}
`}</code></pre>
    <h2>{`Method Decorator`}</h2>
    <p>{`Method decorators allow us to override a method’s function, change its control flow, and execute additional code before/after it runs.`}</p>
    <p>{`The following decorator will show a confirm message in the browser before executing the method. If the user clicks cancel, it will be bypassed. Notice how we have two decorators stacked below - they will be applied from top to bottom.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`export class Person {
  names = [];

  @Confirmable('Are you sure?')
  @Confirmable('Are you super, super sure? There is no going back!')
  addName(name) {
    this.names.push(name);
  }
}

// Method Decorator
function Confirmable(message: string) {
  return function (target: Object, key: string | symbol, descriptor: PropertyDescriptor) {
    const original = descriptor.value;

      descriptor.value = function( ... args: any[]) {
          const allow = confirm(message);

          if (allow) {
            const result = original.apply(this, args);
            return result;
          } else {
            return null;
          }
    };

    return descriptor;
  };
}
`}</code></pre>
    <p>{`Originally added to support the Angular framework and it also requires the `}<em parentName="p">{`experimentalDecorators`}</em>{` property to be set in tsconfig.json.`}</p>
    <p>{`Their implementation has not yet been standardised by TC39, so any code you write today using decorators is liable to break or become non-standard in the future. Unless you’re using Angular or another framework that requires annotations and until they’re standardised, don’t use TypeScript’s decorators.`}</p>
    <h2>{`Enums`}</h2>
    <p>{`Enums allow a developer to define a set of named constants,  instead of using numbers to avoid mistakes. For example, instead of using numbers like 1, 2 and 3, named constants like MONDAY, TUESDAY and WEDNESDAY can be used. This way the programmer doesn't have to remember which number to use.`}</p>
    <p>{`Many programming languages use this concept. TypeScript also adds them to JavaScript:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`enum WeekDay { 
    MONDAY = 0, 
    TUESDAY = 1, 
    WEDNESDAY = 2,
    THURSDAY = 3,
    FRIDAY = 4,
    SATURDAY = 5,
    SUNDAY = 6
}

const Wednesday = WeekDay.WEDNESDAY;
`}</code></pre>
    <p>{`But with TypeScript, enums have some quirks. There are actually several variants on enums that all have subtly different behaviours.`}</p>
    <p>{`Before looking into these behaviours, let's get familiarised with a term called `}<em parentName="p">{`substitution`}</em>{`.`}</p>
    <h3>{`Substitution`}</h3>
    <p>{`For performance and code size reasons, it's often preferable to have a reference to an enum member replaced by its numeric equivalent when compiled:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`const enum Weekday { THURSDAY = 4 }
const Thursday = Weekday.THURSDAY; // emitted as "var Thursday = 4;"
`}</code></pre>
    <p>{`Sometimes you will `}<em parentName="p">{`not`}</em>{` want enum members to be inlined, for example, because the enum value might change in a future version of the API.`}</p>
    <p>{`Now let's look at the different variants of enums available in TypeScript.`}</p>
    <h3>{`const`}</h3>
    <p>{`An enum declaration can have the `}<inlineCode parentName="p">{`const`}</inlineCode>{` modifier. If an enum is `}<inlineCode parentName="p">{`const`}</inlineCode>{`, `}<em parentName="p">{`all`}</em>{` references to its members are inlined.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`const enum Weekday { THURSDAY = 4 }
const Thursday = Weekday.THURSDAY; // emitted as "var Thursday = 4;", always
`}</code></pre>
    <p>{`const enums do not produce a lookup object when compiled. That means const enums go away completely at runtime. For this reason, it is an error to reference `}<inlineCode parentName="p">{`Weekday`}</inlineCode>{` in the above code except as part of a member reference. No `}<inlineCode parentName="p">{`Weekday`}</inlineCode>{` object will be present at runtime. `}</p>
    <h3>{`const with -preserveConstEnums flag`}</h3>
    <p>{`This flag has exactly one effect: non-declare const enums will emit a lookup object. `}<em parentName="p">{`Substitution`}</em>{` is not affected. This is useful for debugging.`}</p>
    <h3>{`number`}</h3>
    <p>{`A number-valued enum like `}<inlineCode parentName="p">{`Weekday`}</inlineCode>{`. This is not safe as not any number can be assigned to this. It was originally designed to make bit flag structures possible.`}</p>
    <h3>{`string`}</h3>
    <p>{`A string-valued enum. This offers type safety, and also more transparent values at runtime. But unlike every other type in TypeScript,  it is not structurally typed. `}</p>
    <p>{`Since every other type in TypeScript uses structural typing for assignability, nominally typed string-valued enums come as a surprise:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`enum WeekDay { 
    MONDAY = 'Monday', 
    TUESDAY = 'Tuesday', 
    WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
  SATURDAY = 'Saturday',
  SUNDAY = 'Sunday'
}

let Wednesday = WeekDay.WEDNESDAY; // Type is WeekDay
Wednesday = 'Wednesday'; // Error: Type '"Wednesday"' is not assignable to type 'WeekDay'.
`}</code></pre>
    <p>{`This could course issues if you are exporting this as a module.`}</p>
    <p>{`Let's assume you have a function that takes this enum as a parameter.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`function assignDayOfWeek(weekday: WeekDay) {
  ...
}
`}</code></pre>
    <p>{`If we are using this library inside a TypeScript project, we will have to import the enum and use that instead of passing in the day of the week as a string:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import { WeekDay } from 'days';

assignDayOfWeek('Wednesday'); // Error!

assignDayOfWeek(WeekDay.Wednesday);
`}</code></pre>
    <p>{`But  if the user is only using JavaScript, this can be used directly:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`assignDayOfWeek('Wednesday'); // Ok!
`}</code></pre>
    <p>{`This inconsistency is one reason we should avoid using string-valued enums. The other reason is the amount of code emitted by the TypeScript compiler:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`"use strict";
var WeekDay;
(function (WeekDay) {
    WeekDay["MONDAY"] = "Monday";
    WeekDay["TUESDAY"] = "Tuesday";
    WeekDay["WEDNESDAY"] = "Wednesday";
    WeekDay["THURSDAY"] = "Thursday";
    WeekDay["FRIDAY"] = "Friday";
    WeekDay["SATURDAY"] = "Saturday";
    WeekDay["SUNDAY"] = "Sunday";
})(WeekDay || (WeekDay = {}));
`}</code></pre>
    <p>{`Instead, if you chose an alternative to enums that offered by TypeScript: a union of literal types.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`type WeekDay = 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday';
`}</code></pre>
    <p>{`This offers has the advantage of translating more directly to JavaScript and as much safety as the enum. It also offers similarly strong autocomplete in your editor:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`function assignDayOfWeek(weekday: WeekDay) { 
    if (weekday === 'W) // Autocomplete here suggests 'Wednesday' 
}
`}</code></pre>
    <p>{`And once compiled the type will go away, reducing the bundle size.`}</p>
    <p>{`Read more about string literal types versus enums here:  `}<a parentName="p" {...{
        "href": "https://szaranger.medium.com/whats-an-enum-65db967d8bb6"
      }}>{`https://szaranger.medium.com/whats-an-enum-65db967d8bb6`}</a></p>
    <h2><strong parentName="h2">{`Parameter Properties`}</strong></h2>
    <p>{`Parameter properties let us create and initialise member variables in one place. It is a shorthand for creating member variables.`}</p>
    <p>{`So far we have been doing this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`class Person{
    private name: string;
    private age: number;
    constructor(name: string, age: number) {
        this.name = name;
        this.age = age;
    }
}
`}</code></pre>
    <p>{`This can be replaced with parameter properties like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`class Person {
    constructor(private name: string, private age: number) {
    }
}
`}</code></pre>
    <p>{`Usually TypeScript compilation erases types at compilation. But parameter properties would generate code after compilation. Here is the generated code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`"use strict";
class Person {
    constructor(name, age) {
        this.name = name;
        this.age = age;
    }
}
`}</code></pre>
    <p>{`This could be confusing to the reader of the code because the parameter is only used in generated code, but the source looks like it has unused parameters.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`class Person {
    constructor(private name: string, private age: number) {
            // what happens to the parameters here ?
    }
}
`}</code></pre>
    <p>{`Mixing up parameter and non-parameter properties appear to be hiding the design of your classes.`}</p>
    <p>{`For example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`class Person {
    number: string;
    street: string;
    constructor(address: string) {
        [this.number, this.street] = address.split(',');
  }
}
`}</code></pre>
    <p>{`There are three properties here: number, street and address, but it's hard to identify as there are only two properties listed before the constructor.`}</p>
    <p>{`Because of this, it is recommended to avoid hiding the design of your class by using a mix of parameter and non-parameter properties.`}</p>
    <h2>{`Namespaces`}</h2>
    <p>{`If you are coming from languages like C# or C++ this seems like the most natural way to go. TypeScript used namespaces before ECMAScript 2015 because JavaScript didn't have a standard module system. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`namespace Person { 
    function run() {}
}
`}</code></pre>
    <p>{`But since  ECMAScript 2015, namespaces have become a legacy feature. It is also not a part of the ECMAScript. And the TypeScript team will continue to follow the standard. There has been no improvement regarding TypeScript namespaces since the release of the ECMAScript modules standard in July 2014.`}</p>
    <h3><strong parentName="h3">{`Triple-Slash Imports`}</strong></h3>
    <p>{`To resolve module imports, before ES2015 TypeScript used a module keyword and `}<em parentName="p">{`triple-slash`}</em>{` imports. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`namespace Person { 
    function getName() {}
}

/// <reference path="Person.ts"/>
Person.getName();
`}</code></pre>
    <p>{`This is no longer the standard of importing modules in TypeScript anymore. You should always use ES2015 style imports and exports.`}</p>
    <h2>{`Summary`}</h2>
    <p>{`Avoid following TypeScript features as much as you can:`}</p>
    <ol>
      <li parentName="ol">{`Decorators`}</li>
      <li parentName="ol">{`Enums`}</li>
      <li parentName="ol">{`Parameter properties`}</li>
      <li parentName="ol">{`Namespaces`}</li>
      <li parentName="ol">{`Triple-Slash Imports`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      